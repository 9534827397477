import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import {reduxForm, Field, InjectedFormProps, DecoratedFormProps} from "redux-form";
import FormWrapper from "../../../../components/FormWrapper";
import { Offer } from "../OfferCard/interfaces";
import style from "./style.scss";
import {listRecallReasons} from "../../../../actions";
import Select from "../../../../components/Select";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { DELIVERY_LOCATIONS, OFFER_TYPES } from "../../../../constants";
import { translateOptions } from "../../../../utils/i18n";
import DateTimePicker from "../../../../components/DateTimePicker";
import {hasFilledRequiredFields, normalizeNumberDecimalToDec} from "../../../../utils/reduxForm";
import {formatNumber, getCurrencySymbolFromCache, getErrors, isFutureDate} from "../../../../utils";
import {getLocalisedName, getQualityLocalName} from "../../../../utils/i18n";
import moment from "moment";
import i18n from "i18next";
import {Deposit} from "../../../Deposits/DepositCard/deposit-dtos";
import Loading from "../../../../components/Loading";
import DatePicker from "../../../../components/DatePicker";

export interface ExchangeLocation {
  id: number;
  name: string;
  location: number;
  physical_address: string;
  assignments: ExchangeLocationAssignment[];
}

export interface ExchangeLocationOption {
  label: string;
  value: {
    deliveryLocationType: "custom_exchange_location" | "pick_from_aggregator" | "drop_at_trader";
    custom_delivery_location?: number;
    custom_delivery_location_type?: number;
  };
}

export interface ExchangeLocationAssignment {
  trader_organization: number;
  types: number[];
}

export interface CounterOfferProps {
  offer: Offer;
  onValidate: () => Promise<void>;
  onCancel: () => void;
  t: (s: string, options?: any) => string;
  fieldErrors: any;
  customExchangeLocations: ExchangeLocation[];
  aggregators: [];
  trader: any;
  administrativeAreas: {id: number, name: string}[],
  exchangeLocationTypes: {id: number, name: string}[];
}

interface Location {
  value: string;
  label: string;
  translationKey: string;
}

interface State {
  isLoading: boolean;
  assignedDeposit?: Deposit;
}

const CounterOfferModal: React.FC<CounterOfferProps> = ({
  offer,
  onValidate,
  onCancel,
  t,
  fieldErrors,
  customExchangeLocations,
  aggregators,
  trader,
  administrativeAreas,
  exchangeLocationTypes,
}) => {
  const state = useState({
    isLoading: true,
    assignedDeposit: undefined,
  });

  useEffect(() => {
    listRecallReasons();
  }, []);

  const onSubmit = () => {
    if (!Object.keys(fieldErrors).length) {
      onValidate();
    }
  };

  const offerTypeHeader = () => {
    if (offer) {
      const translation = (offer.offer_type === OFFER_TYPES.MARKET_OFFER)
      ? t("marketOfferView.marketOffer")
      : t("marketOfferView.directOffer");
      return translation.toUpperCase();
    } else {
      return "";
    }
  };

  const offerCurrency = getCurrencySymbolFromCache();
  const unit = offer?.unit_of_measure?.code;
  const quality = t(getQualityLocalName(offer?.quality));
  const commodityLocalName = getLocalisedName(offer?.buy_commodity);
  const aggregator: any = aggregators.find((item: any) => item.id ===  offer.tagged_aggregator?.id)

  const deliveryLocationOptions: {
    value: string;
    label: string;
    translationKey: string;
    location: string;
  }[] = translateOptions(DELIVERY_LOCATIONS, t).map((location: Location) => {
    const deliveryLocation = location.value === "drop_at_trader"
      ? trader.physical_address || ""
      : aggregator?.location_details?.name || "";

    return {
      ...location,
      label: deliveryLocation ? `${location.label}: ${deliveryLocation}` : location.label,
      location: `${deliveryLocation}`,
    };
  });

  const formattedCustomExchangeLocations = customExchangeLocations.filter(
    (location) => {
      return !deliveryLocationOptions.find((option) => {
        const currentOptionAddress = option && option.location;
        if (currentOptionAddress) {
          return currentOptionAddress == location.physical_address;
        }
        return true;
      });
    }
  );

  const aggregatedDeliveryLocations = useMemo(() => {
    const customLocations = formattedCustomExchangeLocations.reduce(
      (prev: any, item: ExchangeLocation) => {
        const traderAssignment = item.assignments.find(
          (assignment) => assignment.trader_organization === trader.id
        );
        if (!traderAssignment) {
          return prev;
        }

        const location = administrativeAreas
          ? administrativeAreas.find((area) => area.id === item.location)
          : undefined;

        return [
          ...prev,
          ...traderAssignment.types.map((type) => {
            const exchangeLocationType = exchangeLocationTypes.find(
              (t) => t.id === type
            );
            return {
              label: `${item.name}${
                exchangeLocationType ? ", " + exchangeLocationType.name : ""
              }${location ? ", " + location.name : ""}`,
              value: {
                deliveryLocationType: "custom_exchange_location",
                custom_delivery_location: item.id,
                custom_delivery_location_type: type,
              },
            };
          }),
        ];
      },
      []
    );

    return [...deliveryLocationOptions, ...customLocations];
  }, [
    deliveryLocationOptions,
    formattedCustomExchangeLocations,
    trader,
    administrativeAreas,
    exchangeLocationTypes,
  ]);

  return (
    <>
      <Modal
        modalLabel={
          <div>
            {offerTypeHeader()} #{offer.id}
          </div>
        }
        isOpen={!!offer.id}
        validateText={t("counterOfferModal.submitButtonText")}
        title={t("counterOfferModal.heading")}
        onValidate={onSubmit}
        onCancel={onCancel}
        type={"danger"}
        className={style.dangerModal}
        primaryButtonDisabled={!!Object.keys(fieldErrors).length}
      >
        {offer?.offer_type === OFFER_TYPES.DIRECT_OFFER &&
        !offer.deposit?.quantity ? (
          <Loading isVisible />
        ) : (
          <FormWrapper>
            <Field
              errorMessage={getErrors(fieldErrors, "minimum_quantity")}
              component={Input}
              name="minimum_quantity"
              id="minimum_quantity"
              normalize={normalizeNumberDecimalToDec(2)}
              placeholder={t("counterOfferModal.quantityPlaceholder")}
              label={
                <MandatoryLabel
                  label={t(
                    offer?.offer_type === OFFER_TYPES.DIRECT_OFFER
                      ? "counterOfferModal.quantity"
                      : "counterOfferModal.minimum_quantity",
                    {
                      commodity: commodityLocalName,
                      unit,
                      quality,
                    }
                  )}
                />
              }
            />
            {offer?.offer_type !== OFFER_TYPES.DIRECT_OFFER && (
              <Field
                errorMessage={getErrors(fieldErrors, "maximum_quantity")}
                component={Input}
                name="maximum_quantity"
                id="maximum_quantity"
                normalize={normalizeNumberDecimalToDec(2)}
                placeholder={t("counterOfferModal.quantityPlaceholder")}
                label={
                  <MandatoryLabel
                    label={t("counterOfferModal.maximum_quantity", {
                      commodity: commodityLocalName,
                      unit,
                      quality,
                    })}
                  />
                }
              />
            )}
            <Field
              errorMessage={getErrors(fieldErrors, "price")}
              component={Input}
              name="price"
              id="price"
              normalize={normalizeNumberDecimalToDec(2)}
              placeholder={t("counterOfferModal.pricePlaceholder")}
              label={
                <MandatoryLabel
                  label={t("counterOfferModal.price", {
                    unit,
                    currency: offerCurrency,
                  })}
                />
              }
            />
            <Field
              errorMessage={getErrors(fieldErrors, "delivery_location")}
              component={Select}
              options={aggregatedDeliveryLocations}
              name="delivery_location"
              id="delivery_location"
              placeholder={t("counterOfferModal.deliveryLocationPlaceholder")}
              label={
                <MandatoryLabel
                  label={t("counterOfferModal.exchangeLocation")}
                />
              }
            />
            <Field
              component={DatePicker}
              withTime={true}
              isOutsideRange={isFutureDate}
              errorMessage={getErrors(fieldErrors, "planned_exchange_time")}
              name="planned_exchange_time"
              id="planned_exchange_time"
              validate={inFuture}
              placeholder={t("counterOfferModal.exchangeDateTimePlaceholder")}
              label={
                <MandatoryLabel
                  label={t("counterOfferModal.exchangeDateTime")}
                />
              }
              props={{
                hoursLabel: t("common.hours"),
                minutesLabel: t("common.minutes"),
                timeRange: {
                  min: 4,
                  max: 18,
                },
              }}
            />
            <Field
              component={Input}
              name="text"
              id="text"
              label={t("counterOfferModal.comments")}
            />
          </FormWrapper>
        )}
      </Modal>
    </>
  );
};

const requiredFields = [
  "minimum_quantity",
  "price",
  "delivery_location",
  "planned_exchange_time",
];

const inFuture = (value: any) => {
  if (value) {
    if (moment(value).isBefore(moment())) {
      return i18n.t("incorrect-exchange-date-or-time");
    }
  }
};

export const cantBeHigherThenDepositQuantity = (value: string, depositQuantity: string | number) => {
  return value && Number(value) <= Number(depositQuantity) ? undefined : "is-bigger-then-deposit-quantity";
};

const validate = (
  values: {
    planned_exchange_time: any;
    minimum_quantity: string;
    maximum_quantity?: string;
  },
  props: DecoratedFormProps<any, CounterOfferProps, string>
) => {
  const errors: {
    planned_exchange_time?: string;
    minimum_quantity?: string;
    maximum_quantity?: string;
  } = {};

  const plannedExchangeTime = values.planned_exchange_time;
  const offer = props?.offer;

  if (plannedExchangeTime) {
    if (moment(plannedExchangeTime).isBefore(moment())) {
      errors.planned_exchange_time = i18n.t(
        "counterOfferModal.validation.incorrectExchangeDateOrTime"
      );
    }
  }

  if (
    values.minimum_quantity &&
    offer?.offer_type === OFFER_TYPES.DIRECT_OFFER &&
    offer.deposit?.quantity
  ) {
    if (
      cantBeHigherThenDepositQuantity(
        values.minimum_quantity,
        offer.deposit?.quantity
      )
    ) {
      errors.minimum_quantity = i18n.t(
        "counterOfferModal.validation.cantBeMoreThanOriginalDepositQuantity",
        {
          quantity: formatNumber(offer.deposit?.quantity),
          unit: offer.unit_of_measure.code,
        }
      );
    }
  }

  if (Number(values.maximum_quantity) > 99999.99999) {
    errors.maximum_quantity = i18n.t(
      "marketOfferDetails.form.quantityIsTooLarge"
    );
  }
  if (Number(values.minimum_quantity) > 99999.99999) {
    errors.minimum_quantity = i18n.t(
      "marketOfferDetails.form.quantityIsTooLarge"
    );
  }

  hasFilledRequiredFields(requiredFields, values, errors);
  return errors;
};

export default withTranslation()(
  reduxForm<any, CounterOfferProps>({
    form: "counterOffer",
    enableReinitialize: true,
    validate,
  })(CounterOfferModal)
);
