import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { HelpMessage } from "../../components/HelpMessage";
import { listHelpMessages } from "../../actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const sanitisePath = (path) => {
  // remove special characters to make a url safe search term for the backend,
  // made from the react router path.
  const urlSafePath = path.replace(/\//g, "_").replace(/:/g, "");

  let sanitisedPath = urlSafePath;
  if (sanitisedPath[0] === "_") {
    sanitisedPath = sanitisedPath.slice(1);
  }

  if (sanitisedPath[sanitisedPath.length - 1] === "_") {
    sanitisedPath = sanitisedPath.slice(0, -1);
  }

  return sanitisedPath;
};

export const HelpContext = React.createContext(undefined);

// A class to render a route with a configurable help message blurb from the backend
export class HelpMessageRoute extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    try {
      const { listHelpMessages } = this.props;

      const messagesString = localStorage.getItem("help_messages");
      const messages = messagesString ? JSON.parse(messagesString) : [];

      if (!messages || !messages.length) {
        listHelpMessages();
      }
    } catch {
      console.warn("Couldn't load help messages");
    }
  }

  getRelevantMessage() {
    try {
      const messagesString = localStorage.getItem("help_messages");
      const messages = messagesString ? JSON.parse(messagesString) : [];
      const desiredName = sanitisePath(this.props.path);
      const message = messages.find((m) => m.name === desiredName);
      return message;
    } catch {
      console.warn(
        "Something went wrong while getting help message for this page",
      );
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   return (
  //     this.props.path !== nextProps.path
  //   );
  // }

  getLocalText(message) {
    if (!message) {
      return;
    }
    const { i18n } = this.props;
    return message.translations[i18n.language] || message.text;
  }

  render() {
    const helpMessage = this.getRelevantMessage();
    const message = helpMessage && (
      <HelpMessage message={this.getLocalText(helpMessage)} />
    );
    return (
      <HelpContext.Provider value={message}>
        <Route {...this.props} />
      </HelpContext.Provider>
    );
  }
}

// PropTypes for the RepresentativeRoute component
HelpMessageRoute.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  listHelpMessages: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

HelpMessageRoute.defaultProps = {
  messages: [],
  listHelpMessages: () => {},
};

export const mapDispatchToProps = (dispatch) => {
  return {
    listHelpMessages: (path) => dispatch(listHelpMessages(path)),
  };
};

export const mapStateToProps = (state) => {
  const messages =
    state.listHelpMessages && state.listHelpMessages.data.results;
  if (messages && messages.length > 0) {
    localStorage.setItem("help_messages", JSON.stringify(messages));
  }
  return {
    messages: state.listHelpMessages && state.listHelpMessages.data.results,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(HelpMessageRoute)));
