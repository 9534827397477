import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  isTokenExpired,
  isUserRepresentative,
  redirectToCorrectLocation,
  setupClient,
} from "../../utils/authentication";
import RepresentativeHeader from "../RepresentativeHeader";
import RepresentativeFooter from "../RepresentativeFooter";
import PageInformation from "../../components/PageInformation";
import { withTranslation } from "react-i18next";

// A class to render a route protected by login
export class RepresentativeRoute extends Component {
  // By default the content is loading and the user is considered as not logged in
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };
  }

  // Here we check if the authentication token exists
  // and if user profile is representative
  componentDidMount() {
    const ciamAccessToken = localStorage.getItem("ciam_access_token");
    const userProfile = localStorage.getItem("user_profile");
    const expiryDate = new Date(localStorage.getItem("expiry_date"));
    if (!ciamAccessToken || !userProfile || isTokenExpired(expiryDate)) {
      this.setState({ isLoading: false, isLoggedIn: false });
      this.props.history.push("/login");
    } else if (!isUserRepresentative(userProfile)) {
      redirectToCorrectLocation(userProfile, this.props.history);
    } else {
      setupClient(ciamAccessToken);
      this.setState({ isLoading: false, isLoggedIn: true });
    }
  }

  // The component is rendered based on state
  // if there is a network error or the page is loading, a message is displayed
  // if the user is logged in, the component is displayed
  // if there are no errors but the user is not logged in, a redirecting message is displayed
  render() {
    const { t } = this.props;

    if (this.state.isLoading) {
      return (
        <PageInformation
          title={t("route.checkingUser.title")}
          text={t("route.checkingUser.text")}
        />
      );
    }

    if (this.state.isLoggedIn) {
      return (
        <>
          <RepresentativeHeader />
          <Route key={this.props.path} {...this.props} />
          <RepresentativeFooter />
        </>
      );
    }

    return (
      <PageInformation
        title={t("route.redirect.title")}
        text={t("route.redirect.title")}
      />
    );
  }
}

// PropTypes for the RepresentativeRoute component
RepresentativeRoute.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default withTranslation()(withRouter(RepresentativeRoute));
