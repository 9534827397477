import { reducer as formReducer } from "redux-form";

import currentPage from "./currentPage";
import currentSort from "./currentSort";
import resourceAdd from "./resourceAdd";
import resourceDelete from "./resourceDelete";
import resourceDetail from "./resourceDetail";
import resourceList from "./resourceList";
import resourceVersion from "./resourceVersion";
import resourceUpdate from "./resourceUpdate";
import { routerReducer } from "../store";

// Here we combine all reducers to create a root reducer for redux
export default () => ({
  addAggregator: resourceAdd("AGGREGATOR"),
  addAggregatorAdministrator: resourceAdd("AGGREGATOR_ADMINISTRATOR"),
  addAggregatorCreationRequest: resourceAdd("AGGREGATOR_CREATION_REQUEST"),
  addCommodity: resourceAdd("COMMODITIES"),
  addManager: resourceAdd("MANAGERS"),
  addMarketOffer: resourceAdd("MARKET_OFFER"),
  addTrader: resourceAdd("PARTNER"),
  addTraderRepresentative: resourceAdd("PARTNER_REPRESENTATIVE"),
  administrativeAreaTypesDetail: resourceDetail("ADMINISTRATIVE_AREA_TYPES"),
  administrativeAreasDetail: resourceDetail("ADMINISTRATIVE_AREAS"),
  aggregatorAdministratorDetails: resourceDetail("AGGREGATOR_ADMINISTRATOR"),
  aggregatorAdministratorsCurrentPage: currentPage("AGGREGATOR_ADMINISTRATORS"),
  aggregatorCreationRequestApprove: resourceUpdate(
    "AGGREGATOR_CREATION_REQUEST_APPROVE",
  ),
  aggregatorCreationRequestCurrentPage: currentPage(
    "AGGREGATOR_CREATION_REQUESTS",
  ),
  aggregatorCreationRequestDetails: resourceDetail(
    "AGGREGATOR_CREATION_REQUEST",
  ),
  aggregatorCreationRequestReject: resourceUpdate(
    "AGGREGATOR_CREATION_REQUEST_REJECT",
  ),
  aggregatorDetails: resourceDetail("AGGREGATOR"),
  aggregatorMarketOrdersCurrentPage: currentPage("AGGREGATOR_MARKET_ORDERS"),
  aggregatorsCurrentPage: currentPage("AGGREGATORS"),
  aggregatorUmbrellaOrganizationsDetails: resourceDetail(
    "AGGREGATOR_UMBRELLA_ORGANIZATION",
  ),
  applicationVersion: resourceVersion("APPLICATION"),
  commoditiesCurrentPage: currentPage("COMMODITIES"),
  commodityQualityGradesDetail: resourceDetail("COMMODITY_QUALITY_GRADES"),
  countryDashboard: resourceDetail("COUNTRY_DASHBOARD"),
  countryMcfKpiDashboard: resourceDetail("COUNTRY_MCF_KPI_DASHBOARD"),
  createOfferNegotiationProposal: resourceAdd("NEGOTIATIONS"),
  deleteAggregator: resourceDelete("AGGREGATOR"),
  deleteAggregatorAdministrator: resourceDelete("AGGREGATOR_ADMINISTRATOR"),
  deleteAggregatorCreationRequest: resourceDelete(
    "AGGREGATOR_CREATION_REQUEST",
  ),
  deleteTrader: resourceDelete("PARTNER"),
  deleteTraderRepresentative: resourceDelete("PARTNER_REPRESENTATIVE"),
  depositDeletionReasonsDetail: resourceDetail("DEPOSIT_DELETION_REASONS"),
  depositsCurrentPage: currentPage("DEPOSITS"),
  depositsCurrentSort: currentSort("DEPOSITS"),
  exchangeLocationsDetail: resourceDetail("EXCHANGE_LOCATIONS"),
  farmerDeletionReasonsDetail: resourceDetail("FARMER_DELETION_REASONS"),
  termsAndConditions: resourceList("TERMS_OF_USE"),
  termsAndConditionsTextDetails: resourceDetail("TERMS_OF_USE"),
  farmerIdentificationDetail: resourceDetail("FARMER_IDENTIFICATION"),
  farmersCurrentPage: currentPage("FARMERS"),
  form: formReducer,
  getRejectionReason: resourceList("REJECTION_REASONS"),
  helpMessagesDetail: resourceDetail("HELP_MESSAGE"),
  listAdministrativeAreas: resourceList("ADMINISTRATIVE_AREAS"),
  listAdministrativeAreaTypes: resourceList("ADMINISTRATIVE_AREA_TYPES"),
  listAggregatorAdministrators: resourceList("AGGREGATOR"),
  listAggregatorCreationRequests: resourceList("AGGREGATOR_CREATION_REQUESTS"),
  listAggregatorMarketOrders: resourceList("AGGREGATOR_MARKET_ORDERS"),
  listAggregators: resourceList("AGGREGATORS"),
  listAggregatorsForOffer: resourceList("AGGREGATORS_FOR_OFFER"),
  listAggregatorUmbrellaOrganizations: resourceList(
    "AGGREGATOR_UMBRELLA_ORGANIZATION",
  ),
  listCIAMUsersPendingCreation: resourceList("CIAM_USERS"),
  listCommodities: resourceList("COMMODITIES"),
  listCommodityQualityGrades: resourceList("COMMODITY_QUALITY_GRADES"),
  listCountries: resourceList("COUNTRIES"),
  listConfiguration: resourceList("CONFIGURATION"),
  listDepositDeletionReasons: resourceList("DEPOSIT_DELETION_REASONS"),
  listDeposits: resourceList("DEPOSITS"),
  listExchangeLocations: resourceList("EXCHANGE_LOCATIONS"),
  listExchangeLocationTypes: resourceList("EXCHANGE_LOCATION_TYPES"),
  listFarmerDeletionReasons: resourceList("FARMER_DELETION_REASONS"),
  listFarmerIdentification: resourceList("FARMER_IDENTIFICATION"),
  listFarmers: resourceList("FARMERS"),
  listGroupedDeposits: resourceList("GROUPED_DEPOSITS"),
  listHelpMessages: resourceList("HELP_MESSAGE"),
  listLanguages: resourceList("LANGUAGES"),
  listLatestUserActivity: resourceList("LATEST_USER_ACTIVITY"),
  listManagers: resourceList("MANAGERS"),
  listMarketOffers: resourceList("MARKET_OFFERS"),
  listMineAggregators: resourceList("MINE_AGGREGATORS"),
  listTermsAndConditionsText: resourceList("TERMS_OF_USE"),
  listNotificationTextTemplates: resourceList("TEXT_TEMPLATE"),
  listNotificationEvents: resourceList("NOTIFICATION_EVENTS"),
  listOfferComments: resourceList("OFFER_COMMENTS"),
  listOfferNegotiationProposals: resourceList("NEGOTIATIONS"),
  listOrgLegalStatuses: resourceList("ORG_LEGAL_STATUSES"),
  listOrgTypes: resourceList("ORG_TYPES"),
  listPaymentMethods: resourceList("PAYMENT_METHODS"),
  listProducts: resourceList("PRODUCTS"),
  listRecallReasons: resourceList("RECALL_REASONS"),
  listRecalls: resourceList("RECALL"),
  listRejections: resourceList("REJECTION"),
  listTraderMarketOfferNegotiationProposalReasons: resourceList(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
  ),
  listTraderMarketOfferRejectionReasons: resourceList(
    "TRADER_MARKET_OFFER_REJECTION_REASONS",
  ),
  listTraderRepresentatives: resourceList("PARTNER_REPRESENTATIVES"),
  listTraders: resourceList("PARTNERS"),
  listUnitsOfMeasure: resourceList("UNITS_OF_MEASURE"),
  managersCurrentPage: currentPage("MANAGERS"),
  marketOfferDetails: resourceDetail("MARKET_OFFER"),
  marketOffersCurrentPage: currentPage("MARKET_OFFERS"),
  paymentMethodsDetail: resourceDetail("PAYMENT_METHODS"),
  recallReasonsDetails: resourceDetail("RECALL_REASONS"),
  router: routerReducer,
  sendBulkSms: resourceAdd("BULK_SMS"),
  setTraderLogo: resourceAdd("PARTNER_LOGO"),
  traderDetails: resourceDetail("PARTNER"),
  traderMarketOfferRejectionReasonsDetails: resourceDetail(
    "TRADER_MARKET_OFFER_REJECTION_REASONS",
  ),
  traderMarketOfferNegotiationProposalReasonsDetails: resourceDetail(
    "TRADER_MARKER_OFFER_NEGOTIATION_PROPOSAL_REASONS",
  ),
  traderRepresentativeDetails: resourceDetail("PARTNER_REPRESENTATIVE"),
  tradersCurrentPage: currentPage("PARTNERS"),
  treeAdministrativeAreas: resourceList("ADMINISTRATIVE_AREAS_TREE"),
  unitsOfMeasureDetail: resourceDetail("UNITS_OF_MEASURE"),
  updateAggregator: resourceUpdate("AGGREGATOR"),
  updateAggregatorAdministrator: resourceUpdate("AGGREGATOR_ADMINISTRATOR"),
  updateAggregatorCreationRequest: resourceUpdate(
    "AGGREGATOR_CREATION_REQUEST",
  ),
  updateCommodity: resourceUpdate("COMMODITIES"),
  updateManager: resourceUpdate("MANAGERS"),
  updateTrader: resourceUpdate("PARTNER"),
  updateTraderRepresentative: resourceUpdate("PARTNER_REPRESENTATIVE"),
  uploadFarmers: resourceAdd("UPLOAD_FARMERS"),
  userDetail: resourceDetail("USER"),
  listAllInfoHubSubjects: resourceList("INFOHUB_SUBJECTS_ALL"),
  listCountryInfoHubSubjects: resourceList("INFOHUB_SUBJECTS_COUNTRY"),
  listInfoHubTopics: resourceList("INFOHUB_TOPICS"),
  infoHubSubjectDetail: resourceDetail("INFOHUB_SUBJECTS"),
  listInfoHubSubjectAggregators: resourceList("INFOHUB_SUBJECTS_AGGREGATOR"),
  infoHubAddAggregatorToSubject: resourceAdd("INFOHUB_SUBJECTS_AGGREGATOR"),
  addExternalUser: resourceAdd("EXTERNAL_USER"),
  listExternalUsers: resourceList("EXTERNAL_USER"),
  updateExternalUser: resourceUpdate("EXTERNAL_USER"),
  externalUsersCurrentPage: currentPage("EXTERNAL_USER"),
});
