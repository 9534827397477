import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  adaptInitialFormValues,
  getTitleName,
} from "../../../../utils/wfpManagerConfigurationUtils";
import {
  addTermsAndConditionsTextDetails,
  termsAndConditionsTextDetails,
  termsAndConditionsTextDetailsReset,
  listTermsAndConditionsText,
} from "../../../../actions";
import { getCountryObjectFromCache } from "../../../../utils";

export const formatFormValues = (formValues) => ({
  ...formValues,
  target: formValues.target && formValues.target.value,
  country: getCountryObjectFromCache().id,
});

export const TermsAndConditionsTextEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  termsAndConditionsTextDetails,
  termsAndConditionsTextDetailsReset,
  addTermsAndConditionsTextDetails,
  listTermsAndConditionsText,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    termsAndConditionsTextDetails(modalOptions.id);
    return () => {
      termsAndConditionsTextDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(
      adaptInitialFormValues(initialFormValues, modalOptions.id),
    );
  }, [initialFormValues]);

  const onSubmit = (formValues) => {
    const updatedValues = formatFormValues(formValues);
    addTermsAndConditionsTextDetails(updatedValues).then(() =>
      listTermsAndConditionsText(),
    );
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.termsAndConditionsText") +
        " " +
        t(getTitleName(modalOptions.type))
      }
      onCancel={() =>
        setModalOptions({ type: undefined, isOpen: false, id: undefined })
      }
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={
        modalOptions.type !== "view"
          ? t("negotiationCard.confirm")
          : t("common.back")
      }
    >
      {modalOptions.isOpen && (
        <Form
          id={modalOptions.id}
          initialValues={initialValues}
          isFetching={isFetching}
          errorMessage={errorMessage}
          modalOptions={modalOptions}
          onSubmit={onSubmit}
          buttonRef={buttonRef}
        />
      )}
    </Modal>
  );
};

TermsAndConditionsTextEditModal.defaultProps = {};

TermsAndConditionsTextEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  termsAndConditionsTextDetails: PropTypes.func,
  termsAndConditionsTextDetailsReset: PropTypes.func,
  addTermsAndConditionsTextDetails: PropTypes.func,
  listTermsAndConditionsText: PropTypes.func,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.termsAndConditionsTextDetails.data,
    isFetching: state.termsAndConditionsTextDetails.isFetching,
    errorMessage: state.termsAndConditionsTextDetails.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    termsAndConditionsTextDetails: (id) =>
      dispatch(termsAndConditionsTextDetails(id)),
    termsAndConditionsTextDetailsReset: () =>
      dispatch(termsAndConditionsTextDetailsReset()),
    addTermsAndConditionsTextDetails: (values) =>
      dispatch(addTermsAndConditionsTextDetails(values)),
    listTermsAndConditionsText: () => dispatch(listTermsAndConditionsText()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsTextEditModal);
