import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { change, reset } from "redux-form";

import {
  listCommodities,
  listUnitsOfMeasure,
  addMarketOffer,
  addMarketOfferReset,
  listTraders,
  listExchangeLocations,
  addOfferComment,
  listExchangeLocationTypes,
  treeAdministrativeAreas,
} from "../../actions";
import Form from "./components/Form";
import {
  getStatusErrorMessage,
  valueOrDefault,
  formatDateInput,
  formatNumber,
  getCountryObjectFromCache,
} from "../../utils";
import {
  adaptCommodities,
  adaptUnitsOfMeasure,
  adaptTrader,
  adaptAdministrativeAreas,
} from "../../utils/adapters";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import { orderByLocalName } from "../../utils/i18n";
import { OfferStatus } from "../RepresentativeHome/components/OfferCard/interfaces";
import { v4 as uuidv4 } from "uuid";
import Button from "../../components/Button";
import style from "./style.scss";

export const isDeposit = (depositOrCommodity) => !!depositOrCommodity.id;

// A function to adapt values entered in form to a format accepted by the server
export const adaptDirectOfferFromValues = (
  formValues,
  depositOrCommodity,
) => {
  const quantity = valueOrDefault(formValues.minimum_quantity, undefined)
   || depositOrCommodity.quantity;

  const has_custom_exchange_location = typeof formValues.delivery_location.value === "object";

  return {
    buy_commodity: isDeposit(depositOrCommodity)
      ? depositOrCommodity.commodity.id
      : depositOrCommodity.commodity_id,
    unit_of_measure: isDeposit(depositOrCommodity)
      ? depositOrCommodity.unit_of_measure.id
      : depositOrCommodity.unit_of_measure_id,
    quality: valueOrDefault(depositOrCommodity.quality, undefined),
    delivery_location: has_custom_exchange_location ? formValues.delivery_location.value.deliveryLocationType : valueOrDefault(
      formValues.delivery_location && formValues.delivery_location.value,
      undefined,
    ),
    tagged_aggregator: isDeposit(depositOrCommodity)
      ? depositOrCommodity.aggregator.id
      : depositOrCommodity.aggregator_id,
    negotiable: valueOrDefault(formValues.negotiable && formValues.negotiable.value, undefined),
    price: valueOrDefault(formValues.price, undefined) || depositOrCommodity.price,
    minimum_quantity: quantity,
    maximum_quantity: quantity,
    valid_until: valueOrDefault(
      formatDateInput(formValues.valid_until),
      undefined,
    ),
    contract_number: valueOrDefault(formValues.contract_number, undefined),
    offer_type: "DIRECT_OFFER",
    deposit: valueOrDefault(depositOrCommodity.id, -1),
    planned_exchange_time: formValues.exchange_date_direct && formValues.exchange_date_direct.toISOString ? formValues.exchange_date_direct.toISOString() : undefined,
    comment: valueOrDefault(formValues.comment, undefined),
    custom_delivery_location: has_custom_exchange_location
      ? formValues.delivery_location.value.custom_delivery_location
      : undefined,
    custom_delivery_location_type: has_custom_exchange_location
      ? formValues.delivery_location.value.custom_delivery_location_type
      : undefined,
  };
};

// This component is used to display the detail
// of a Market Offer
export const DirectOfferDetails = ({
  treeAdministrativeAreas,
  administrativeAreas,
  isFetching,
  errorMessage,
  listCommodities,
  listUnitsOfMeasure,
  unitsOfMeasure,
  fieldErrors,
  formErrors,
  formValues,
  addMarketOffer,
  addMarketOfferReset,
  history,
  trader,
  listTraders,
  deposit,
  isOpen,
  onCancel,
  resetForm,
  listExchangeLocations,
  listExchangeLocationTypes,
  customExchangeLocations,
  exchangeLocationTypes,
  setAsNegotiable,
}) => {
  const { t } = useTranslation();
  const [depositQuantityErrorMessage, setDepositQuantityErrorMessage] = useState(undefined);
  const [isValid, setIsValid] = useState(false);

  // On component mount the data are fetched
  // market offer if fetched if we are in edit
  // on component unmount data are reset
  useEffect(() => {
    listCommodities();
    listUnitsOfMeasure();
    listTraders();
    listExchangeLocations();
    listExchangeLocationTypes();

    const country = getCountryObjectFromCache();
    if (country) {
      treeAdministrativeAreas(country.id);
    }
    return () => {
      addMarketOfferReset();
    };
  }, []);

  // On click validate a request is made
  const onClickValidate = () => {
    const data = adaptDirectOfferFromValues(
      formValues,
      linkedDeposit,
      unitsOfMeasure,
    );
    setDepositQuantityErrorMessage(null);
    addMarketOffer(data).then((result) => {
      if (result) {
        history.push("/representative/");
      }
    });
  };

  const linkedDeposit = deposit || {};
  const initialValues = {
    minimum_quantity: formatNumber(linkedDeposit.quantity, false),
    price: formatNumber(linkedDeposit.price, false),
  };

  const renderModalFooter = (modalProps) => {
    return (
      <div style={{marginTop: "-1rem", marginBottom: "-1rem"}}>
        <Button kind={"secondary"} onClick={() => modalProps.onSecondarySubmit()}>
          {modalProps.secondaryButtonText}
        </Button>
        <Button disabled={modalProps.primaryButtonDisabled} kind={"primary"} onClick={() => modalProps.onRequestSubmit()}>
          {modalProps.primaryButtonText}
        </Button>
      </div>
    );
  };

  return (
    <div className={style.directOfferDetailsModal}>
      <Modal
        isOpen={isOpen}
        onValidate={onClickValidate}
        onCancel={() => {
          resetForm();
          addMarketOfferReset();
          setDepositQuantityErrorMessage(null);
          onCancel();
        }}
        validateText={t("directOfferDetails.form.sendOffer")}
        passiveModal={isFetching}
        primaryButtonDisabled={!isValid}
        footer={renderModalFooter}
      >
        <Form
          administrativeAreas={administrativeAreas}
          customExchangeLocations={customExchangeLocations}
          exchangeLocationTypes={exchangeLocationTypes}
          initialValues={initialValues}
          isFetching={isFetching}
          errorMessage={errorMessage}
          fieldErrors={fieldErrors}
          formErrors={formErrors}
          trader={trader}
          deposit={linkedDeposit}
          depositQuantityExceededError={depositQuantityErrorMessage}
          setIsValid={setIsValid}
          linkedDeposit={linkedDeposit}
          setAsNegotiable={setAsNegotiable}
          t={t}
        />
      </Modal>
    </div>
  );
};

// propTypes for the MarketOfferDetails component
DirectOfferDetails.propTypes = {
  administrativeAreas: PropTypes.array.isRequired,
  treeAdministrativeAreas: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  listCommodities: PropTypes.func.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  addMarketOffer: PropTypes.func.isRequired,
  addMarketOfferReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  listTraders: PropTypes.func.isRequired,
  trader: PropTypes.object.isRequired,
  deposit: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  listExchangeLocations: PropTypes.func.isRequired,
  customExchangeLocations: PropTypes.array.isRequired,
  listExchangeLocationTypes: PropTypes.func.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  setAsNegotiable: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Properties related to market offer details
    // if we are on creation, no market offer is fetched
    // Loading and errors properties
    isFetching:
      state.listCommodities.isFetching ||
      state.listUnitsOfMeasure.isFetching ||
      state.listTraders.isFetching ||
      state.addMarketOffer.isLoading,
    errorMessage:
      state.listCommodities.errorMessage ||
      state.listUnitsOfMeasure.errorMessage ||
      state.listTraders.errorMessage ||
      getStatusErrorMessage(state.addMarketOffer.error) ||
      "",
    // Related entities lists
    commodities: adaptCommodities(orderByLocalName(state.listCommodities.data.results)),
    unitsOfMeasure: adaptUnitsOfMeasure(state.listUnitsOfMeasure.data.results),
    administrativeAreas: adaptAdministrativeAreas(
      state.treeAdministrativeAreas.data.administrative_areas,
    ),
    customExchangeLocations: state.listExchangeLocations.data.results,
    exchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    // Form errors
    fieldErrors: state.addMarketOffer.error,
    formErrors: (state.form.directOfferDetails && state.form.directOfferDetails.syncErrors) || undefined,
    // Form values
    formValues:
      (state.form.directOfferDetails && state.form.directOfferDetails.values) ||
      {},
    // Trader details to get the currency
    trader: adaptTrader(state.listTraders.data.results),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    treeAdministrativeAreas: (countryId) =>
      dispatch(treeAdministrativeAreas({}, `${countryId}/administrative_areas/`)),
    listExchangeLocations: () => dispatch(listExchangeLocations()),
    listExchangeLocationTypes: () => dispatch(listExchangeLocationTypes()),
    listCommodities: () => dispatch(listCommodities()),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    addMarketOffer: (data) => dispatch(addMarketOffer(data)),
    addMarketOfferReset: () => dispatch(addMarketOfferReset()),
    listTraders: () => dispatch(listTraders()),
    setAsNegotiable: (option) => {
      dispatch(change("directOfferDetails", "negotiable", option));
    },
    setUnitOfMeasurement: (unit) =>
      dispatch(change("directOfferDetails", "unit_of_measure", unit)),
    clearMinimumQuantity: () =>
      dispatch(change("directOfferDetails", "min_deposit_quantity", undefined)),
    resetForm: () => dispatch(reset("directOfferDetails")),
    addOfferComment: (text, offerId) => dispatch(addOfferComment(
      {
        trader_market_offer: offerId,
        text,
        uuid: uuidv4(),
        trader_market_offer_status: OfferStatus.New,
      },
    )),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DirectOfferDetails));
