import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { iconDocument } from "@wfp/icons";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import PageStandardWithTabs from "../../components/PageStandardWithTabs";
import SummaryView from "./components/SummaryView";
import { getCountryObjectFromCache } from "../../utils";
import { List as UserActivityList } from "../UserActivity/components/List";
import {
  listLatestUserActivity,
  listLatestUserActivityReset,
} from "../../actions";
import { connect } from "react-redux";
import { UserGroup } from "../../utils/roles";
import { isUserGuest } from "../../utils/authentication";
import MastercardFundationSummaryView from "./components/MastercardFundationSummaryView";
/**
 * The home wfp manager page component.
 */
export const WfpManagerHome = ({
  latestUserActivity,
  isFetching,
  errorMessage,
  listLatestUserActivity,
  listLatestUserActivityReset,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    listLatestUserActivity();
    return () => {
      listLatestUserActivityReset();
    };
  }, []);

  const onClickPrintHome = () => {
    window.print();
  };

  const country = getCountryObjectFromCache();

  const userProfile = localStorage.getItem("user_profile");
  const _isUserGuest = isUserGuest(userProfile);

  const tabs = useMemo(() => {
    const result = [
      {
        id: "summary",
        label: t("home.manager.tabs.summary"),
        Component: SummaryView,
      },
      {
        id: "aggregator Summary",
        label: t("home.manager.tabs.aggregatorTitle"),
        Component: UserActivityList,
        props: {
          latestUserActivity,
          errorMessage,
          isFetching,
          filterUserRole: UserGroup.AGGREGATOR_ADMIN,
        },
        hide: false,
      },
      {
        id: "Trader Summary",
        label: t("home.manager.tabs.traderTitle"),
        Component: UserActivityList,
        props: {
          latestUserActivity,
          errorMessage,
          isFetching,
          filterUserRole: UserGroup.TRADER_REPR,
        },
        hide: false,
      },
      {
        id: "manager Summary",
        label: t("home.manager.tabs.managerTitle"),
        Component: UserActivityList,
        props: {
          latestUserActivity,
          errorMessage,
          isFetching,
          filterUserRole: UserGroup.WFP_MANAGER,
        },
        hide: _isUserGuest,
      },
    ];
    if (country.code === "MZ") {
      result.push({
        id: "MCF Summary",
        label: t("home.manager.tabs.youthInWorkProgramme"),
        Component: MastercardFundationSummaryView,
      });
    }
    return result;
  }, [country]);

  return (
    <PageStandardWithTabs
      title={`${t("home.manager.title")} ${t("common.in")} ${country.name}`}
      additional={
        <ButtonsWrapper>
          <Button icon={iconDocument} onClick={onClickPrintHome}>
            {t("common.print")}
          </Button>
        </ButtonsWrapper>
      }
      tabs={tabs}
    />
  );
};

// propTypes for the WfpManagerHome component
WfpManagerHome.propTypes = {
  latestUserActivity: PropTypes.array,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  listLatestUserActivity: PropTypes.func.isRequired,
  listLatestUserActivityReset: PropTypes.func.isRequired,
};
export const mapStateToProps = (state) => ({
  latestUserActivity: state.listLatestUserActivity.data.results,
  isFetching: state.listLatestUserActivity.isFetching,
  errorMessage: state.listLatestUserActivity.errorMessage,
});

export const mapDispatchToProps = (dispatch) => ({
  listLatestUserActivity: () => dispatch(listLatestUserActivity()),
  listLatestUserActivityReset: () => dispatch(listLatestUserActivityReset()),
});

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WfpManagerHome));
