/* eslint-disable indent */
import i18n from "i18next";
import { getCountryObjectFromCache } from ".";
import { DELIVERY_LOCATIONS, QUALITIES } from "../constants";

export const I18nConst = {
  LANGUAGE_LOCAL_STORAGE_KEY: "langCode",
  DEFAULT_LANGUAGE: "en",
};

export const languages = [
  { value: "bn_BD", engLabel: "Bengali", label: "বাংলা", flag: "BD" },
  { value: "en", label: "English", flag: "GB" },
  { value: "pt_MZ", engLabel: "Portuguese", label: "Português", flag: "MZ" },
  { value: "rw", label: "Kinyarwanda", flag: "RW" },
  { value: "fil_PH", label: "Filipino", flag: "PH" },
  { value: "so", label: "Soomaali", flag: "SO" },
  { value: "es_CU", label: "Español", flag: "CU" },
  { value: "fr_FR", label: "Français", flag: "GN" },
  {
    value: "ht",
    label: "Creole",
    flag: "HT",
  },
];

export const getLanguageForCountryCode = (code) => {
  switch (code) {
    case "NI":
      return { value: "es_CU", label: "Española", flag: "CU" };
    default:
      return languages.find((lang) => lang.flag === code);
  }
};

export const displaySelectedCountryTranslation = (original, field, t) => {
  const code = getCountryObjectFromCache().code;
  const translationCode = getLanguageForCountryCode(code);
  if (!translationCode) return t("systemNotifications.noTranslatedText");
  const hasTranslation =
    Object.keys(original).includes(field) &&
    (Object.keys(original[field]).includes(translationCode.value) ||
      Object.keys(original[field]).includes(code));

  let returnTranslations;
  if (hasTranslation) {
    returnTranslations =
      original[field][translationCode.value] || original[field][code];
  }
  return returnTranslations
    ? returnTranslations
    : t("systemNotifications.noTranslatedText");
};

export const onChangeLanguage = async (option) => {
  localStorage.setItem(I18nConst.LANGUAGE_LOCAL_STORAGE_KEY, option.value);
  await i18n.changeLanguage(option.value);
};

export const getUserLanguage = () => {
  const lang = localStorage.getItem(I18nConst.LANGUAGE_LOCAL_STORAGE_KEY);
  return lang ? lang : I18nConst.DEFAULT_LANGUAGE;
};

export const getLocalisedName = (object) => {
  if (!object) {
    return;
  }
  return (
    (object.name_translations && object.name_translations[getUserLanguage()]) ||
    object.name
  );
};

export const translateOptions = (options, t) => {
  return options.map((o) => ({
    ...o,
    label: t(o.translationKey),
  }));
};

export const translateNameFromListResponse = (entity_name, listOfEntities) => {
  if (!entity_name || !listOfEntities || !listOfEntities.length) {
    return;
  }
  const matchingEntity = listOfEntities.find((e) => e.name === entity_name);
  return getLocalisedName(matchingEntity);
};

export const orderByLocalName = (list) =>
  list
    .slice()
    .sort((a, b) => (getLocalisedName(a) < getLocalisedName(b) ? -1 : 1));

export const getDeliveryLocationNameKey = (location_string) => {
  const locationObject = DELIVERY_LOCATIONS.find(
    (l) => l.value === location_string,
  );
  return locationObject ? locationObject.translationKey : location_string;
};

export const getQualityLocalName = (quality_code) => {
  const qualityObject = QUALITIES.find((q) => q.value === quality_code);
  return qualityObject ? qualityObject.translationKey : quality_code;
};

export const formatLanguageLabel = (language) =>
  language && language.engLabel
    ? `${language.engLabel} (${language.label})`
    : language.label;
